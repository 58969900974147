import { Link } from 'react-router-dom';
import { DASHBOARD_PATH } from 'config';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import ft_logo from 'assets/images/ft_logo.png';
import m_fm_icon2 from 'assets/images/m_fm_icon2.png';
import m_fm_icon3 from 'assets/images/m_fm_icon3.png';
import m_fm_icon1 from 'assets/images/m_fm_icon1.png';
import m_fm_icon4 from 'assets/images/m_fm_icon4.png';

const Footer = ({ hideMenu }) => {
	const dispatch = useDispatch();
	const { drawerOpen } = useSelector((state) => state.menu);

	return (
		<>
			<footer id="footer">
				<div className="area clear">
					<div className="right">
					<ul className="clear">
							{/*
							<li>
								<a href="">회사소개</a>
							</li>
							<li>
								<a href="">이용약관</a>
							</li>
							<li>
								<a href="">개인정보처리방침</a>
							</li>
							*/}
							<li>
								<a href="https://talents4share.notion.site/dd9c86c3095440af8f2bef9c00979bdd?v=d84cfd9413b740fb9190d07f098f37ef" target="_blank">FAQ</a>
							</li>
						</ul>

						<h2 className="ft_logo">
							<Link to={process.env.NODE_ENV === 'production' ? DASHBOARD_PATH : '/dev'}>
								<img src={ft_logo} width="240" height="33" border="0" alt="" />
							</Link>
						</h2>
					</div>

					<div className="left">
						<ul>
							<li className="clear">
								<p className="ft_p1">주식회사 탤런츠</p>
								<p className="ft_p1">대표: 김희정</p>
								<p>경기 화성시 동탄산단 2길 7-3 4층</p>
							</li>

							<li className="clear">
								<p>사업자등록번호: 233-87-02822</p>
								<p>통신판매업신고번호: 2023-화성동탄-1509</p>
								<p>고객센터: talents@talents-on.com</p>
							</li>

							<li className="clear">
								<p>유료직업소개사업등록번호 : (국내)제 2022-5530415-14-5-0004</p>
							</li>

							<li className="clear">
								<p>Copyright© Talents co., ltd. 2023 ~ All Rights reserved.</p>
							</li>
						</ul>
					</div>
				</div>
			</footer>
			{!hideMenu && (
				<div className="floating_menu">
					<ul className="clear">
						<li>
							<Link to={process.env.NODE_ENV === 'production' ? DASHBOARD_PATH : '/dev'}>
								{/* <a href="index.html"> */}
								<span className="bar"></span>
								<span className="icon">
									<img src={m_fm_icon2} width="96" height="96" border="0" alt="" />
								</span>
								<h4>홈</h4>
								{/* </a> */}
							</Link>
						</li>

						<li>
							<Link to={`/my_profile`}>
								<span className="bar"></span>
								<span className="icon">
									<img src={m_fm_icon3} width="96" height="96" border="0" alt="" />
								</span>
								<h4>내 프로필</h4>
							</Link>
						</li>

						<li>
							<Link to={`/my_talents`}>
								<span className="bar"></span>
								<span className="icon">
									<img src={m_fm_icon1} width="96" height="96" border="0" alt="" />
								</span>
								<h4>마이 탤런츠</h4>
							</Link>
						</li>

						<li className="fm_m_gnb">
							<span className="icon" onClick={() => dispatch(openDrawer(!drawerOpen))}>
								<img src={m_fm_icon4} width="96" height="96" border="0" alt="" />
							</span>
							<h4>메뉴</h4>
						</li>
					</ul>
				</div>
			)}
		</>
	);
};

export default Footer;
