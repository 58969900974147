import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const RegisterGuard = ({ children }) => {
	const { isOauth2Succeed } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isOauth2Succeed) {
			navigate('/login');
		}
	}, [isOauth2Succeed, navigate]);

	return children;
};

export default RegisterGuard;
