import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project imports
import MenuList from './MenuList';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const dispatch = useDispatch();
	const { drawerOpen } = useSelector((state) => state.menu);

	const container = window !== undefined ? () => window.document.body : undefined;

	return (
		<Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
			<Drawer
				// container={container}
				// variant={'persistent'}
				anchor="right"
				open={drawerOpen}
				onClose={() => dispatch(openDrawer(!drawerOpen))}
				sx={{
					'& .MuiDrawer-paper': {
						width: drawerWidth
						// background: theme.palette.background.default,
						// color: theme.palette.text.primary,
						// borderRight: 'none',
						// [theme.breakpoints.up('md')]: {
						// top: '88px'
						// }
					}
				}}
				// ModalProps={{ keepMounted: true }}
				// color="inherit"
			>
				{drawerOpen && <MenuList />}
			</Drawer>
		</Box>
	);
};

Sidebar.propTypes = {
	window: PropTypes.object
};

export default memo(Sidebar);
