import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import TalentsRoutes from './TalentsRoutes';
import TalentsAuthRoutes from './TalentsAuthRoutes';
import TalentsRegisterRoutes from './TalentsRegisterRoutes';
import TalentsRoutesDev from './TalentsRoutes_dev';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
	return useRoutes([LoginRoutes, TalentsRoutes, TalentsAuthRoutes, TalentsRegisterRoutes, TalentsRoutesDev]);
}
