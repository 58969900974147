import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as MaterialLink } from '@mui/material';
import { useSelector, useDispatch } from 'store';
import useAuth from 'hooks/useAuth';
import LogoutLayer from 'layout/TalentsLayout/Header/LogoutLayer';
import { activeItem, openDrawer } from 'store/slices/menu';
import Avatar from 'ui-component/images/Avatar';
import Alert from 'ui-component/popups/Alert_type2';
import { useNavigate } from 'react-router-dom';
import edit2 from 'assets/images/edit2.png';
import mhd_icon1 from 'assets/images/mhd_icon1.png';
import mhd_icon2 from 'assets/images/mhd_icon2.png';
import mhd_icon3 from 'assets/images/mhd_icon3.png';
import mhd_icon4 from 'assets/images/mhd_icon4.png';
import mhd_icon5 from 'assets/images/mhd_icon5.png';
import mhd_icon6 from 'assets/images/mhd_icon6.png';
import mhd_icon7 from 'assets/images/mhd_icon7.png';
import mhd_icon8 from 'assets/images/mhd_icon8.png';
import mhd_icon9 from 'assets/images/mhd_icon9.png';

const Header = () => {
	const { isLoggedIn, logout, user } = useAuth();
	const [showLogoutLayer, setShowLogoutLayer] = useState(false);
	const [executeLogout, setExecuteLogout] = useState();
	const [showAlert, setShowAlert] = useState(false);

	const navigate = useNavigate();

	const dispatch = useDispatch();
	const { drawerOpen } = useSelector((state) => state.menu);

	const handleLogout = () => {
		setShowLogoutLayer(true);
	};

	const handleLinkClick = (id) => {
		if (id === 'register_expert') {
			if (isLoggedIn) {
				setShowAlert(true);
			} else {
				dispatch(activeItem([id]));
				dispatch(openDrawer(false));

				navigate('/login');
			}
			return;
		}

		dispatch(activeItem([id]));
		dispatch(openDrawer(false));
	};

	useEffect(async () => {
		try {
			if (executeLogout) {
				await logout();
				dispatch(openDrawer(false));
			} else {
				// noop
			}
			setShowLogoutLayer(false);
		} catch (err) {
			console.error(err);
		}
	}, [executeLogout]);

	return (
		<>
			{showAlert && <Alert msg={`이미 전문가로 등록 하셨습니다.`} setShowAlert={setShowAlert} zIndex={13} />}
			<div id="mHd" style={{ right: drawerOpen ? '0px' : '-77px' }}>
				<div className="mHd_wrap">
					<div className="mHd_1">
						{!isLoggedIn ? (
							<Link to={`/login`} className="login_btn2" onClick={() => handleLinkClick('login')}>
								로그인하세요 😀
							</Link>
						) : (
							<div className="mHd_1_login">
								{/* <!-- 로그인 후 보이는 콘텐츠 --> */}
								<span className="pf_img">
									<Avatar target={user?.email} style={{ width: '30px', height: '30px' }} alt="" />
									{/* <img src={`/images/profile_common${profileNumber}.png`} width="480" height="480" border="0" alt="" /> */}
								</span>
								<p>
									<span>{user.nickname}</span>님 반갑습니다.
								</p>
								<a href="sub/sub15.html" className="edit_btn">
									<img src={edit2} width="64" height="64" border="0" alt="" />
								</a>
							</div>
						)}
					</div>

					<div className="mHd_2">
						<ul>
							<li>
								<Link to={`/my_profile`} onClick={() => handleLinkClick('my_profile')}>
									<span className="icon">
										<img src={mhd_icon1} width="81" height="80" border="0" alt="" />
									</span>
									<p>내 프로필</p>
								</Link>
							</li>

							<li>
								<Link to={`/interest_list`} onClick={() => handleLinkClick('interest_list')}>
									<span className="icon">
										<img src={mhd_icon2} width="81" height="80" border="0" alt="" />
									</span>
									<p>관심 리스트</p>
								</Link>
							</li>

							{/*<li>
								<Link to={`/my_apply`} onClick={() => handleLinkClick('my_apply')}>
									<span className="icon">
										<img src={mhd_icon3} width="81" height="80" border="0" alt="" />
									</span>
									<p>지원현황</p>
								</Link>
						</li>*/}
						</ul>
					</div>

					<div className="mHd_3">
						<ul>
							<li>
								<Link to={`/contact_us`} onClick={() => handleLinkClick('contact_us')}>
									<span className="icon">
										<img src={mhd_icon4} width="81" height="80" border="0" alt="" />
									</span>
									<p>서비스 의뢰</p>
								</Link>
							</li>

							{/*<li>
								<MaterialLink
									color="inherit"
									component="a"
									style={{ cursor: 'pointer' }}
									onClick={() => handleLinkClick('register_expert')}
								>
									<span className="icon">
										<img src={mhd_icon5} width="81" height="80" border="0" alt="" />
									</span>
									<p>전문가 등록</p>
								</MaterialLink>
							</li>

							<li>
								<Link to={`/paid_advisory`} onClick={() => handleLinkClick('paid_advisory')}>
									<span className="icon">
										<img src={mhd_icon6} width="81" height="80" border="0" alt="" />
									</span>
									<p>유료자문</p>
								</Link>
							</li>

							<li>
								<Link to={`/knowledge_donation`} onClick={() => handleLinkClick('knowledge_donation')}>
									<span className="icon">
										<img src={mhd_icon7} width="81" height="80" border="0" alt="" />
									</span>
									<p>지식기부</p>
								</Link>
							</li>*/}
						</ul>
					</div>

					<div className="mHd_4">
						<ul>
							<li>
								<a href="https://blog.naver.com/talents4share" target="_blank" className="clear">
									<span className="icon">
										<img src={mhd_icon8} width="81" height="80" border="0" alt="" />
									</span>
									<p>탤런츠 블로그</p>
								</a>
							</li>

							{/*<li>
								<Link to={`/notification_space`} onClick={() => handleLinkClick('notification_space')}>
									<span className="icon">
										<img src={mhd_icon9} width="81" height="80" border="0" alt="" />
									</span>
									<p>알림 공간</p>
								</Link>
							</li>*/}

							<li>
								{isLoggedIn && (
									<Link onClick={handleLogout} className="logout_btn">
										로그아웃
									</Link>
								)}
							</li>
						</ul>
					</div>
				</div>
			</div>

			{showLogoutLayer && <LogoutLayer setExecuteLogout={setExecuteLogout} setShowLogoutLayer={setShowLogoutLayer}></LogoutLayer>}
		</>
	);
};

export default Header;
