import Dimmed from 'ui-component/wrapper/Dimmed';

const Alert_type2 = ({ msg, setShowAlert, callbackFunc, zIndex }) => {
	return (
		<>
			<Dimmed {...(zIndex ? (zIndex = { zIndex }) : {})} />
			<div className="chk_pu">
				<h3 style={{ whiteSpace: 'pre-wrap' }}>{msg}</h3>
				<button
					type="button"
					className="re_cnfm"
					onClick={() => {
						setShowAlert(false);
						callbackFunc && callbackFunc();
					}}
				>
					확인
				</button>
			</div>
		</>
	);
};

export default Alert_type2;
