import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Link as MaterialLink } from '@mui/material';

import LogoSection from '../LogoSection';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import Alert from 'ui-component/popups/Alert_type2';

import LogoutLayer from './LogoutLayer';
import alarm from 'assets/images/alarm.png';
import hd_util_req from 'assets/images/hd_util_req.png';

const Header = ({ hideMenu }) => {
	const { logout, isLoggedIn } = useAuth();
	const [showLogoutLayer, setShowLogoutLayer] = useState(false);
	const [executeLogout, setExecuteLogout] = useState();
	const [showAlert, setShowAlert] = useState(false);
	const navigate = useNavigate();

	const handleLogout = () => {
		setShowLogoutLayer(true);
	};

	useEffect(async () => {
		try {
			if (executeLogout) {
				await logout();
			} else {
				// noop
			}
			setShowLogoutLayer(false);
		} catch (err) {
			console.error(err);
		}
	}, [executeLogout]);

	const dispatch = useDispatch();
	const { drawerOpen } = useSelector((state) => state.menu);

	const handleLinkClick = (id) => {
		if (id === 'register_expert') {
			if (isLoggedIn) {
				setShowAlert(true);
			} else {
				navigate('/login');
			}
			return;
		}
	};

	return (
		<>
			{showAlert && <Alert msg={`이미 전문가로 등록 하셨습니다.`} setShowAlert={setShowAlert} zIndex={13} />}
			<header id="header">
				<div className="area">
					<h1 className="logo">
						<LogoSection />
					</h1>

					<div className="gnb">
						<ul className="clear">
							{/*<li>
								<MaterialLink
									style={{ cursor: 'pointer', textDecoration: 'none' }}
									color="inherit"
									component="a"
									onClick={() => handleLinkClick('register_expert')}
								>
									전문가 등록
								</MaterialLink>
							</li>*/}
							{/* 							<li>
								<Link to={`/paid_advisory`}>유료자문</Link>
							</li>
							<li>
								<Link to={`/knowledge_donation`}>지식기부</Link>
							</li>
							<li>
								<Link to={`/my_profile`}>내 프로필</Link>
							</li>
							<li>
								<Link to={`/my_apply`}>지원 현황</Link>
							</li>
							<li>
								<Link to={`/interest_list`}>관심 리스트</Link>
							</li> */}
							<li>
								<a href="https://blog.naver.com/talents4share" target="_blank">
									블로그<span></span>
								</a>
							</li>
							{/* <li>
								<Link to={`/notification_space`}>알림 공간</Link>
							</li> */}
						</ul>
					</div>
					
					{/*
					<div className="util">
						<ul className="clear">
							<li className="arm_btn">
								<Link to={`/notification_space`}>
									<img src={alarm} width="83" height="80" border="0" alt="" />
									<span className="new"></span>
								</Link>
							</li>
							<li className="req_btn">
								<Link to={`/contact_us`}>
									<img src={hd_util_req} width="60" height="60" border="0" alt="" />
									서비스 의뢰
								</Link>
							</li>
							<li className="m_gnb idx_mg">
								<div id="nav-icon1" className="m_ham" onClick={() => dispatch(openDrawer(!drawerOpen))}>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</li>
						</ul>
					</div>
					*/}
				</div>
				{showLogoutLayer && <LogoutLayer setExecuteLogout={setExecuteLogout} setShowLogoutLayer={setShowLogoutLayer}></LogoutLayer>}
			</header>
		</>
	);
};

export default Header;
