import PropTypes from 'prop-types';
// import { createContext, useEffect, useReducer } from 'react';
import { createContext, useEffect } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
// import accountReducer from 'store/accountReducer';

import { useSelector, useDispatch } from 'store';
import { loginSuccess, logoutSuccess } from 'store/slices/account';
import { useNavigate } from 'react-router-dom';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

const chance = new Chance();

const verifyToken = (refreshToken) => {
	if (!refreshToken) {
		return false;
	}
	const decoded = jwtDecode(refreshToken);

	if (decoded.exp > Date.now() / 1000) {
		console.log('JWTContext refreshToken verified...');
	} else {
		console.log('JWTContext refreshToken expired...');

		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		delete axios.defaults.headers.common.Authorization;
		delete axiosAuth.defaults.headers.common.Authorization;
	}

	return decoded.exp > Date.now() / 1000;
};

const setSession = (accessToken, refreshToken) => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken);
		localStorage.setItem('refreshToken', refreshToken);
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	} else {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		delete axios.defaults.headers.common.Authorization;
	}
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// const [state, dispatch] = useReducer(accountReducer, initialState);
	const { isOauth2Succeed, isLoggedIn, user } = useSelector((state) => state.account);

	useEffect(async () => {
		login();
	}, [dispatch]);

	const login = async (accToken, refToken, navigateTo) => {
		try {
			const accessToken = accToken || window.localStorage.getItem('accessToken');
			const refreshToken = refToken || window.localStorage.getItem('refreshToken');

			if (refreshToken && verifyToken(refreshToken)) {
				setSession(accessToken, refreshToken);

				const response = await axios.get('/member/me');
				const user = response.data;

				dispatch(loginSuccess({ user: user }));

				if (navigateTo) {
					if (user.status !== '1') {
						if (process.env.NODE_ENV === 'production') {
							// TODO - 개발에서는 먹지 않도록 해둠.
							navigate('/register');
						} else {
							navigate('/register');
							// navigate(navigateTo);
						}
					}
				}
			} else {
				dispatch(logoutSuccess());
			}
		} catch (err) {
			console.error(err);
			dispatch(logoutSuccess());
		}
	};

	const logout = () => {
		console.log('JWTContext logout');
		setSession(null);
		dispatch(logoutSuccess());
	};

	return <JWTContext.Provider value={{ login, logout, isOauth2Succeed, isLoggedIn, user, setSession }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
	children: PropTypes.node
};

export default JWTContext;
