/**
 * axios setup to use mock service
 */
import axios from 'axios';
import { SERVER_BASE_URL } from 'config';

const axiosServices = axios.create({
	baseURL: SERVER_BASE_URL,
	headers: {
		'content-type': 'application/json'
	}
	// withCredentials: true
});

let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
	refreshSubscribers.map((callback) => callback(accessToken));

	refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
	refreshSubscribers.push(callback);
};

// interceptor for http
axiosServices.interceptors.response.use(
	(response) => response,
	async (error) => {
		// const [state, dispatch] = useReducer(accountReducer);

		let isTokenRefreshing = false;

		console.debug('error', error);

		const {
			config,
			response: { status }
		} = error;

		const originalRequest = config;

		if (error.response && error.response.data) {
			const { data } = error.response;
			if (status === 401 && data.jwt_access_token === 'EXPIRED') {
				// 토큰이 재발급된 후 수행될 요청 저장
				const retryOriginalRequest = new Promise((resolve) => {
					addRefreshSubscriber((accessToken) => {
						originalRequest.headers.Authorization = `Bearer ${accessToken}`;
						resolve(axios(originalRequest));
					});
				});

				// 토큰 재발급 도중 들어온 요청은 스킵
				if (!isTokenRefreshing) {
					isTokenRefreshing = true;
					const accessToken = localStorage.getItem('accessToken');
					const response = await axios
						.post(
							`${SERVER_BASE_URL}/auth/reissue`,
							{ refreshToken: localStorage.refreshToken },
							{
								headers: {
									'content-type': 'application/json',
									Authorization: `Bearer ${accessToken}`
								}
							}
						)
						.then((res) => {
							return res;
						})
						.catch((error) => {
							console.error(error.response);
							console.log('refresh error', error);

							if (error.status === 401 || error.status === 500) {
								// dispatch({ type: LOGOUT });
								console.log('refresh token expired.');
								delete axiosServices.defaults.headers.common.Authorization;
								localStorage.removeItem('accessToken');
								localStorage.removeItem('refreshToken');

								window.location = '/login';
							}
						});

					const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;

					if (newAccessToken) {
						localStorage.setItem('accessToken', newAccessToken);
						axiosServices.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
					} else {
						localStorage.removeItem('accessToken');
						delete axiosServices.defaults.headers.common.Authorization;
					}

					if (newRefreshToken) {
						localStorage.setItem('refreshToken', newRefreshToken);
					}

					isTokenRefreshing = false;

					// 재발급이 완료된 후 들어온 요청들을 재수행
					onTokenRefreshed(newAccessToken);
				}

				return retryOriginalRequest;
			}
		}

		return Promise.reject((error.response && error.response.data) || 'Wrong Services');
	}
);

export default axiosServices;
