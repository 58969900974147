import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import TalentsLayout from 'layout/TalentsLayout';

const MyProfile = lazy(() => import('views/my_profile'));
const MyApply = lazy(() => import('views/my_apply'));
const InterestList = lazy(() => import('views/interest_list'));
const MyTalents = lazy(() => import('views/my_talents'));
const NotificationSpace = lazy(() => import('views/notification_space'));

// ==============================|| MAIN ROUTING ||============================== //

const TalentsAuthRoutes = {
	path: '/',
	element: (
		<AuthGuard>
			<TalentsLayout />
		</AuthGuard>
	),
	children: [
		{
			path: 'my_profile',
			element: <MyProfile />
		},
		{
			path: 'my_talents',
			element: <MyTalents />
		},
		{
			path: 'my_apply',
			element: <MyApply />
		},
		{
			path: 'interest_list',
			element: <InterestList />
		},
		{
			path: 'notification_space',
			element: <NotificationSpace />
		}
	]
};

export default TalentsAuthRoutes;
