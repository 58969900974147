// routing
import Routes from 'routes';

// Material UI
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

// ==============================|| APP ||============================== //

const theme = createTheme({
	typography: {
		fontFamily: `SC5, SC6, 'Noto Sans KR', sans-serif`,
		fontSize: 12
		// fontWeightLight: 300,
		// fontWeightRegular: 400,
		// fontWeightMedium: 500
	}
});

const App = () => (
	// <ThemeCustomization>
	// 	<RTLLayout>
	// 		<Locales>
	// 			<NavigationScroll>
	<ThemeProvider theme={theme}>
		<AuthProvider>
			<>
				<Routes />
			</>
		</AuthProvider>
	</ThemeProvider>
	// 			</NavigationScroll>
	// 		</Locales>
	// 	</RTLLayout>
	// </ThemeCustomization>
);

export default App;
