// third-party
import { combineReducers } from 'redux';

// project imports
import accountReducer from './slices/account';
import menuReducer from './slices/menu';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	menu: menuReducer,
	account: accountReducer
});

export default reducer;
