/* eslint no-use-before-define: 0 */

import React, { Suspense } from 'react';

import 'assets/css/common.css';
import 'assets/css/main.css';
import 'assets/css/custom.css';

import { useTheme } from '@mui/material/styles';
import { LinearProgress, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import { MobileView } from 'react-device-detect';

import Header from './Header';
import Footer from './Footer';

// const Footer = Loadable(lazy(() => import('./Footer')));

const renderLoader = () => <LinearProgress color="primary" />;

const TalentsLayout = (props) => {
	const theme = useTheme();
	const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

	const dispatch = useDispatch();

	/* 	React.useEffect(() => {
		console.log('matchDownMd');
		dispatch(openDrawer(!matchDownMd));
	}, [matchDownMd]); */

	return (
		<>
			<Header {...props} />
			{/* drawer */}
			<Suspense fallback={renderLoader()}>
				{/* <MobileView> */}
				<Sidebar />
				{/* </MobileView> */}
				<Outlet />
			</Suspense>
			<Footer {...props} />
		</>
	);
};

export default TalentsLayout;
