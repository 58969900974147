import React from 'react';
import Dimmed from 'ui-component/wrapper/Dimmed';

const LogoutLayer = (props) => {
	return (
		<>
			<Dimmed zIndex={13}></Dimmed>
			<div className="logout_pu pu">
				<h2>로그아웃 하시겠어요?</h2>
				<div className="btn_wrap clear">
					<button
						className="cancel"
						onClick={() => {
							props.setShowLogoutLayer(false);
						}}
					>
						취소
					</button>
					<button
						className="ok"
						onClick={() => {
							props.setExecuteLogout(true);
						}}
					>
						확인
					</button>
				</div>
			</div>
		</>
	);
};

export default LogoutLayer;
