import { lazy } from 'react';

// project imports
import TalentsLayout from 'layout/TalentsLayout';

// sample page routing
// const Job = Loadable(lazy(() => import('views/job')));
const Main = lazy(() => import('views/main'));
const KnowledgeDonation = lazy(() => import('views/knowledge_donation'));
const KnowledgeDonation_Post = lazy(() => import('views/knowledge_donation/Post'));
const PaidAdvisory = lazy(() => import('views/paid_advisory'));
const ContactUs = lazy(() => import('views/contact_us'));

// ==============================|| MAIN ROUTING ||============================== //

const TalentsRoutes = {
	path: '/',
	element: <TalentsLayout />,
	children: [
		{
			index: true,
			path: '/',
			element: <Main />
		},
		{
			path: 'knowledge_donation',
			children: [
				{
					path: '',
					element: <KnowledgeDonation />
				},
				{
					path: 'post/:id',
					element: <KnowledgeDonation_Post />
				}
			]
		},
		{
			path: 'contact_us',
			element: <ContactUs />
		},
		{
			path: 'paid_advisory',
			element: <PaidAdvisory />
		}
	]
};

export default TalentsRoutes;
