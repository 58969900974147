import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
	const { isLoggedIn } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isLoggedIn) {
			// TODO-JASON : 로그인 필요화면일시에 홈으로 보내도록 임시 수정 (2023-07-05)
			// navigate('/login');
			navigate('/');
		}
	}, [isLoggedIn, navigate]);

	return children;
};

AuthGuard.propTypes = {
	children: PropTypes.node
};

export default AuthGuard;
