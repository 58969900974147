// import { createSlice } from '@reduxjs/toolkit';
// import { dispatch } from 'store';

// const initialState = {
// 	isLoggedIn: false,
// 	isInitialized: false,
// 	user: null
// };

// const slice = createSlice({
// 	name: 'account',
// 	initialState,
// 	reducers: {
// 		loginSuccess(state, action) {
// 			state.isLoggedIn = true;
// 			state.isInitialized = true;
// 			state.user = action.payload.user;
// 		}
// 	}
// });

// export default slice.reducer;

// export function login() {
// 	return () => {
// 		dispatch(slice.actions.loginSuccess({}));
// 	};
// }

import { createSlice } from '@reduxjs/toolkit';

// action - state management
// import { LOGIN, LOGOUT, REGISTER } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
	isOauth2Succeed: false, // oauth2 성공시 true
	isLoggedIn: false, // 회원가입 완료된 사용자 일시 true (실제로 사용될 값)
	user: null
};

const account = createSlice({
	name: 'account',
	initialState,
	reducers: {
		/* 		registerSuccess(state, action) {
			const { user } = action.payload;

			state.user = user || initialState.user;
		}, */

		loginSuccess(state, action) {
			const { user } = { ...action.payload };

			state.user = user || initialState.user;
			state.isOauth2Succeed = true;
			state.isLoggedIn = user?.status === '1';
		},

		logoutSuccess(state) {
			state.user = null;
			state.isOauth2Succeed = false;
			state.isLoggedIn = false;
		}
	}
});

export default account.reducer;

export const { registerSuccess, loginSuccess, logoutSuccess } = account.actions;
