import ReactDOM from 'react-dom';

// third party
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// project imports
import App from 'App';
import { BASE_PATH } from 'config';
import { ConfigProvider } from 'contexts/ConfigContext';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
	<Provider store={store}>
		<ConfigProvider>
			<BrowserRouter basename={BASE_PATH}>
				<App />
			</BrowserRouter>
		</ConfigProvider>
	</Provider>,
	document.getElementById('root')
);
