export const FIREBASE_API = {
	apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
	authDomain: 'berry-material-react.firebaseapp.com',
	projectId: 'berry-material-react',
	storageBucket: 'berry-material-react.appspot.com',
	messagingSenderId: '901111229354',
	appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
	measurementId: 'G-MGJHSL8XW3'
};

// export const SERVER_BASE_URL = 'http://localhost:9001' + '/api';

// export const SERVER_BASE_URL = 'http://www.talents.co.kr' + '/api';
export const SERVER_BASE_URL = `${process.env.REACT_APP_SERVER_BASE_URL}`;

// export const API_BASE_URL = SERVER_BASE_URL + '/api';

export const OAUTH2_REDIRECT_URI = SERVER_BASE_URL + '/oauth2/redirect';

export const GOOGLE_AUTH_URL = SERVER_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const KAKAO_AUTH_URL = SERVER_BASE_URL + '/oauth2/authorize/kakao?redirect_uri=' + OAUTH2_REDIRECT_URI;

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/';

const config = {
	fontFamily: `'Roboto', sans-serif`,
	outlinedFilled: true,
	navType: 'light', // light, dark
	presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
	locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
	rtlLayout: false,
	container: false
};

export default config;
