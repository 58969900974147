import { lazy } from 'react';

// project imports
import TalentsLayout from 'layout/TalentsLayout';

// sample page routing
// const Job = Loadable(lazy(() => import('views/job')));
const DevRoutes = lazy(() => import('views/dev_route_page'));
const KnowledgeDonation = lazy(() => import('views/knowledge_donation'));
const KnowledgeDonation_Post = lazy(() => import('views/knowledge_donation/Post'));
const PaidAdvisory = lazy(() => import('views/paid_advisory'));
const NotificationSpace = lazy(() => import('views/notification_space'));
const ContactUs = lazy(() => import('views/contact_us'));
const MyTalents = lazy(() => import('views/my_talents'));

// auth routes
const Register = lazy(() => import('views/register'));
const RegisterPage2 = lazy(() => import('views/register/Page2'));
const RegisterPage3 = lazy(() => import('views/register/Page3'));
const RegisterPage4 = lazy(() => import('views/register/Page4'));
const RegisterPage5 = lazy(() => import('views/register/Page5'));
const RegisterPage6 = lazy(() => import('views/register/Page6'));
const RegisterPage7 = lazy(() => import('views/register/Page7'));
const RegisterPage8 = lazy(() => import('views/register/Page8'));
const RegisterPage9 = lazy(() => import('views/register/Page9'));
const RegisterPage10 = lazy(() => import('views/register/Page10'));
const RegisterPage11 = lazy(() => import('views/register/Page11'));
const RegisterPage12 = lazy(() => import('views/register/Page12'));
const RegisterPage13 = lazy(() => import('views/register/Page13'));
const RegisterPage14 = lazy(() => import('views/register/Page14'));
const MyProfile = lazy(() => import('views/my_profile'));
const MyApply = lazy(() => import('views/my_apply'));
const InterestList = lazy(() => import('views/interest_list'));
// ==============================|| MAIN ROUTING ||============================== //

const TalentsRoutesDev = {
	path: '/dev',
	element: <TalentsLayout />,
	children: [
		{
			index: true,
			path: '/dev',
			element: <DevRoutes />
		},
		{
			path: 'knowledge_donation',
			children: [
				{
					path: '',
					element: <KnowledgeDonation />
				},
				{
					path: 'post/:id',
					element: <KnowledgeDonation_Post />
				}
			]
		},
		{
			path: 'notification_space',
			element: <NotificationSpace />
		},
		{
			path: 'contact_us',
			element: <ContactUs />
		},
		{
			path: 'my_talents',
			element: <MyTalents />
		},
		{
			path: 'paid_advisory',
			element: <PaidAdvisory />
		},
		{
			path: 'register',
			children: [
				{
					path: '',
					element: <Register />
				},
				{
					path: '2',
					element: <RegisterPage2 />
				},
				{
					path: '3',
					element: <RegisterPage3 />
				},
				{
					path: '4',
					element: <RegisterPage4 />
				},
				{
					path: '5',
					element: <RegisterPage5 />
				},
				{
					path: '6',
					element: <RegisterPage6 />
				},
				{
					path: '7',
					element: <RegisterPage7 />
				},
				{
					path: '8',
					element: <RegisterPage8 />
				},
				{
					path: '9',
					element: <RegisterPage9 />
				},
				{
					path: '10',
					element: <RegisterPage10 />
				},
				{
					path: '11',
					element: <RegisterPage11 />
				},
				{
					path: '12',
					element: <RegisterPage12 />
				},
				{
					path: '13',
					element: <RegisterPage13 />
				},
				{
					path: '14',
					element: <RegisterPage14 />
				}
			]
		},
		{
			path: 'my_profile',
			element: <MyProfile />
		},
		{
			path: 'my_apply',
			element: <MyApply />
		},
		{
			path: 'interest_list',
			element: <InterestList />
		}
	]
};

export default TalentsRoutesDev;
