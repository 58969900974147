import { lazy } from 'react';

import RegisterGuard from 'utils/route-guard/RegisterGuard';
import TalentsLayout from 'layout/TalentsLayout';

const Register = lazy(() => import('views/register'));
const RegisterPage2 = lazy(() => import('views/register/Page2'));
const RegisterPage3 = lazy(() => import('views/register/Page3'));
const RegisterPage4 = lazy(() => import('views/register/Page4'));
const RegisterPage5 = lazy(() => import('views/register/Page5'));
const RegisterPage6 = lazy(() => import('views/register/Page6'));
const RegisterPage7 = lazy(() => import('views/register/Page7'));
const RegisterPage8 = lazy(() => import('views/register/Page8'));
const RegisterPage9 = lazy(() => import('views/register/Page9'));
const RegisterPage10 = lazy(() => import('views/register/Page10'));
const RegisterPage11 = lazy(() => import('views/register/Page11'));
const RegisterPage12 = lazy(() => import('views/register/Page12'));
const RegisterPage13 = lazy(() => import('views/register/Page13'));
const RegisterPage14 = lazy(() => import('views/register/Page14'));

// ==============================|| MAIN ROUTING ||============================== //

const TalentsRegisterRoutes = {
	path: '/',
	element: (
		<RegisterGuard>
			<TalentsLayout hideMenu={true} />
		</RegisterGuard>
	),
	children: [
		{
			path: 'register',
			children: [
				{
					path: '',
					element: <Register />
				},
				{
					path: '2',
					element: <RegisterPage2 />
				},
				{
					path: '3',
					element: <RegisterPage3 />
				},
				{
					path: '4',
					element: <RegisterPage4 />
				},
				{
					path: '5',
					element: <RegisterPage5 />
				},
				{
					path: '6',
					element: <RegisterPage6 />
				},
				{
					path: '7',
					element: <RegisterPage7 />
				},
				{
					path: '8',
					element: <RegisterPage8 />
				},
				{
					path: '9',
					element: <RegisterPage9 />
				},
				{
					path: '10',
					element: <RegisterPage10 />
				},
				{
					path: '11',
					element: <RegisterPage11 />
				},
				{
					path: '12',
					element: <RegisterPage12 />
				},
				{
					path: '13',
					element: <RegisterPage13 />
				},
				{
					path: '14',
					element: <RegisterPage14 />
				}
			]
		}
	]
};

export default TalentsRegisterRoutes;
