import { useState, useEffect } from 'react';
import axios from 'utils/axios';
import seedrandom from 'seedrandom';
import profile_common1 from 'assets/images/profile_common1.png';
import profile_common2 from 'assets/images/profile_common2.png';
import profile_common3 from 'assets/images/profile_common3.png';
import profile_common4 from 'assets/images/profile_common4.png';
import profile_common5 from 'assets/images/profile_common5.png';
import profile_common6 from 'assets/images/profile_common6.png';
import profile_common7 from 'assets/images/profile_common7.png';

const Avatar = (props) => {
	const seed = seedrandom(props.target);
	const int32 = seed.int32();
	const profileNumber = Math.abs(int32 % 7) + 1;

	const profileImages = [
		profile_common1,
		profile_common2,
		profile_common3,
		profile_common4,
		profile_common5,
		profile_common6,
		profile_common7
	];

	const [imgBase64, setImgBase64] = useState(profileImages[profileNumber - 1]);

	useEffect(() => {
		axios({
			method: 'get',
			url: '/member/get_image',
			params: { id: props.id },
			responseType: 'arraybuffer'
		})
			.then((response) => {
				// TODO: 아래 response.data.byteLength 이 부분 다르게 할 방법 확인해서 변경
				if (response.data.byteLength !== 0) {
					const content = response.data;
					const base64String = Buffer.from(content).toString();
					const url = `data:${response.headers['content-type']};base64,${base64String}`;
					setImgBase64(url);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return <img style={{ objectFit: 'cover', borderRadius: '50%', ...props.style }} src={imgBase64} />;
};

export default Avatar;
