import { lazy } from 'react';

// project imports
import TalentsLayout from 'layout/TalentsLayout';
import Loadable from 'ui-component/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));
const Oauth2RedirecHandler = Loadable(lazy(() => import('views/pages/authentication/oauth2/Oauth2RedirectHandler')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
	path: '/',
	element: (
		<GuestGuard>
			<TalentsLayout hideMenu={true} />
		</GuestGuard>
	),
	children: [
		{
			path: '/login',
			element: <AuthLogin />
		},
		{
			path: '/oauth2/redirect',
			element: <Oauth2RedirecHandler />
		}
	]
};

export default LoginRoutes;
