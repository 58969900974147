import styled from 'styled-components';

const Dimmed = styled.div.attrs((props) => ({
	zIndex: props.zIndex || '10'
}))`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.6;
	filter: Alpha(opacity=60);
	z-index: ${(props) => props.zIndex};
`;

export default Dimmed;
